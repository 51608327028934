import Route from '@ember/routing/route';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class AuthenticatedAdminTfeFormsEditLocationAccessRoute extends Route {
  queryParams = {
    page: {
      replace: true,
      refreshModel: true
    },
    query: {
      refreshModel: true
    }
  }

  @service api;
  @service store;
  @service session;

  defaultPage = 1;

  async model(params) {
    const document = await this.modelFor('authenticated.admin.tfe.forms.edit');
    const enabledDocuments = await this.store.query('tfe/enabled-document', {
      params,
      current_client: true,
      document_id: document.get('id'),
      archived: false,
      secondary_remote_identifier_type: 'location',
      page: params.page || this.defaultPage
    });
    const enabledLocations = await this.store.query('v2/location', {
      uuid: enabledDocuments.mapBy('secondaryRemoteIdentifier')
    })

    return {
      document,
      enabledDocuments,
      enabledLocations
    };
  }

  async setupController(controller, model) {
    super.setupController(controller, model);

    controller.setProperties({
      enabledDocuments: model.enabledDocuments,
      enabledLocations: model.enabledLocations,
      meta: model.enabledDocuments.get('meta'),
      isLoading: false,
      canEnableLocations: model.enabledDocuments.length > 0
    });
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      this.store.unloadAll('tfe/document');
      this.store.unloadAll('tfe/enabled_document');

      controller.setProperties({
        enabledDocuments: null,
        enabledLocations: null,
        page: 1,
        meta: null
      });
    }
  }

  @action
  refreshRoute() {
    this.refresh();
  }
}
