import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
export default class AuthenticatedAdminFinancialConfigurationsEditBankAccountsEditRoute extends Route { 
    @service router;
    @service session;
    @service intl
    async model(params){
        const coa = this.modelFor('authenticated.admin.financial-configurations.edit');
        const accounts = await this.store.query('v2/account', {
            chart_of_accounts_id: coa.get('id'),
            archived: false,
            per_page: null,
            ...params,
        });
        const bankAccount = await this.store.findRecord('tribute-pay/v1/bank-account', params.id);

        const checkTemplates = await this.store.query('tfe/document', {
          current_client: true,
          enabled: true,
          is_public: 'all',
          per_page: null,
          usage_type: 'check_template',
        });

        return { accounts, bankAccount, checkTemplates };
    }
    beforeModel() {
        if (
          !this.session.currentUser.isSuperuser &&
          !this.session.currentClient.can_view_financial_config_bank_accounts
        ) {
          this.router.transitionTo('authenticated.dashboard');
        }
    }
    setupController(controller, model) {
      super.setupController(controller, model);
      controller.set('bankName', model.bankAccount.get('bankName'));
    }
    resetController(controller, isExiting) {
      if(controller.bankAccount.hasDirtyAttributes) {
        controller.bankAccount.rollbackAttributes();
      }
      if(isExiting){
        controller.setProperties({
          showStaffMemberSearch: false,
          accountNumber: null,
          routingNumber: null,
        });
      }
    }

    @action
    willTransition(transition) {
      if (this.currentModel.bankAccount.hasDirtyAttributes && !confirm(this.intl.t('authenticated.admin.financial-configurations.edit.bank-accounts.edit.messages.cancelEdit.confirm'))) {
        transition.abort();
      }
    }   
}
