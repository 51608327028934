import Model, { attr } from '@ember-data/model';

export default class TributePayV1BankAccountModel extends Model {
    @attr('string') accountNumberLastFour;
    @attr('string') bankAccountName;
    @attr('array')  bankAccountStaffAssignments;
    @attr('string') bankName;
    @attr('string') checkTemplateId;
    @attr('string', { defaultValue: 90 }) dateRange;
    @attr('string') generalLedgerAccountId;
    @attr('string') generalLedgerAccountName;
    @attr('boolean', { defaultValue: false }) isCheckSettingsEnabled;
    @attr('boolean', { defaultValue: false }) isDateRangeEnabled;
    @attr('string') routingNumberLastFour;
    @attr('string') startingCheckNumber;
    @attr('string') nextAvailableCheckNumber;
    @attr('string') chartOfAccountsId;
}
