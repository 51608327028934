/* eslint-disable ember/no-computed-properties-in-native-classes */
import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action, computed, set } from '@ember/object';
import moment from 'moment';
import { validator, buildValidations } from 'ember-cp-validations';
import { inject as service } from '@ember/service';
import { reads } from '@ember/object/computed';
import { formatFloat } from 'crakn/utils/data-helpers';

const Validations = buildValidations({
  amount: validator('number', {
    gt: 0.00,
    allowString: true,
    message: reads('model.checkAmountValidationMessage')
  }),
  bankAccountId: validator('presence', {
    presence: true
  }),
  date: validator('presence', {
      presence: true,
      message: reads('model.checkDateValidationMessage')
  }),
  number: [
    validator('presence', {
      presence: true,
      message: reads('model.checkNumberValidationMessage')
    }),
    validator('number', {
    integer: true,
    gt: 0,
    allowString: true,
    message: reads('model.checkNumberValidationMessage')
  })],
  payeeName: validator('presence', {
    presence: true,
    disabled: computed.not('model.isOneTimePayee')
  }),
  newPayee: validator('presence', {
    presence: true,
    disabled: reads('model.isOneTimePayee')
  }),

});

export default class AuthenticatedChecksNewController extends Controller.extend(
  Validations) {
  @service flashes;
  @service intl;
  @service router;
  @service api;
  @service store;

  @tracked amount = 0;
  @tracked bankAccountId;
  @tracked date = moment();
  @tracked dateRange = 90;
  @tracked isOneTimePayee = false;
  @tracked memo;
  @tracked newPayee;
  @tracked number;
  @tracked payeeName;
  @tracked showValidations = false;
  @tracked showVoidConfirmDilog = false;
  @tracked showPrintConfirmDilog = false;
  @tracked isCheckGenerationStarted = false;

  @action
  updateBankAccount(bankAccountId) {
    set(this, 'bankAccountId', bankAccountId);
    set(this, 'date', moment());
    set(this, 'dateRange', 90);
    set(this, 'number', null);

    if (bankAccountId) {
      const bankAccount = this.model.find(({ id }) => id === bankAccountId);
      if (bankAccount) {
        set(this, 'dateRange', bankAccount.dateRange);
        set(this, 'number', bankAccount.nextAvailableCheckNumber||bankAccount.startingCheckNumber);
        set(this, 'checkTemplateId', bankAccount.checkTemplateId);
      }
    }
  }

  @action
  updateCheckAmount(amount) {
    set(this, 'amount', amount);
  }

  @action
  updateCheckDate(checkDate) {
    set(this, 'date', checkDate ? moment(checkDate) : null);
  }

  get checkDateValidationMessage() {
    return this.intl.t('authenticated.checks.new.messages.validation.checkDate', { dateRange: this.dateRange });
  }

  get checkAmountValidationMessage() {
    return this.intl.t('authenticated.checks.new.messages.validation.checkAmount');
  }

  get checkNumberValidationMessage() {
    return this.intl.t('authenticated.checks.new.messages.validation.checkNumber');
  }

  get isOneTimePayee() {
    return this.isOneTimePayee;
  }

  @action
  saveAsDraft() {
    this.validate().then(({validations})=>{
      if (validations.get('isValid')){
        set(this, 'showValidations', false);
        const check = this.constructPayload();
        check.save().then(()=>{
          this.flashes.addSuccess(this.intl.t('authenticated.checks.new.messages.saveAsDraft.success'));
          this.router.transitionTo('authenticated.checks.index');
        }).catch((error)=>{
          const checkNumberError = error?.errors?.find((err) => err.source?.pointer === '/data/attributes/number');
          if (checkNumberError) {
            this.flashes.addError(this.intl.t('authenticated.checks.new.messages.saveAsDraft.checkNumberError'));
          } else {
            this.flashes.addError(this.intl.t('authenticated.checks.new.messages.saveAsDraft.error'));
          }
          });
      } else {
        set(this, 'showValidations', true);
      }
    });
  }
  
  @action
  addNewPayee(value){
    set(this, 'newPayee', value);
  }

  @action
  toggleIsOneTimePayee(value) {
    set(this, 'isOneTimePayee', value);
    if(!value) set(this, 'payeeName', undefined);
  }

  @action
  saveContact(){
    return this.newPayee.save();
  }

  constructPayload(payload){
    const check = this.store.createRecord('tribute-pay/v1/check', {
      amount: payload?.amount || this.amount,
      bankAccountId: payload?.bankAccountId || this.bankAccountId,
      date: payload?.date || this.date,
      isOneTimePayee: payload?.isOneTimePayee || this.isOneTimePayee,
      memo: payload?.memo || this.memo,
      number: payload?.number || this.number,
      payeeId: payload?.payeeId || this.newPayee?.id,
      payeeName: payload?.payeeName || this.payeeName || this.newPayee?.name,
      status: payload?.status || 'draft'
    });
    return check
  }

  @action
  voidCheck() {
    this.validate().then(({validations})=>{
      if (validations.get('isValid')){
        set(this, 'showValidations', false);
        set(this, 'showVoidConfirmDilog', true);
      }
      else{
        set(this, 'showValidations', true);
      }
    });
  }

  @action
  printCheck() {
    this.validate().then(({validations})=>{
      if (validations.get('isValid')){
        set(this, 'showValidations', false);
        set(this, 'showPrintConfirmDilog', true);
      }
      else{
        set(this, 'showValidations', true);
      }
    });
  }

  @action
  confirmVoidingCheck() {
    set(this, 'showVoidConfirmDilog', false);
    const check = this.constructPayload({status: 'voided'});
    check.save().then(() => {
      this.flashes.addSuccess(this.intl.t('authenticated.checks.new.messages.void.success'));
      this.router.transitionTo('authenticated.checks.check-details.edit', check.id);
    }).catch((error) => {
      const checkNumberError = error?.errors?.find((err) => err.source?.pointer === '/data/attributes/number');
      if (checkNumberError) {
        this.flashes.addError(this.intl.t('authenticated.checks.new.messages.void.checkNumberError'));
      } else {
        this.flashes.addError(this.intl.t('authenticated.checks.new.messages.void.error'));
      }
    });
  }

  @action
  async confirmPrintingCheck() {
    set(this, 'showPrintConfirmDilog', false);
    set(this, 'isCheckGenerationStarted', true);
    try{
      const response = await this.api.json.post(
        `tfe/documents/${this.checkTemplateId}/generate`,
        {
          body: {
            bank_account_id: this.bankAccountId,
            form_data: {
                amount: formatFloat(this.amount, false, false, 2, false),
                bank_account_id: this.bankAccountId,
                date: this.date.format('YYYY-MM-DD'),
                is_one_time_payee: this.isOneTimePayee,
                memo: this.memo,
                number: this.number,
                payee_id: this.newPayee?.id,
                payee_name: this.payeeName || this.newPayee?.name
            }
          }
        }
      );

      if(!response.ok){
        throw new Error(response.parsedJson.errors.number[0]);
      }

      set(this, 'isCheckGenerationStarted', false);
      this.router.transitionTo('authenticated.checks.check-details.edit', response.parsedJson.generated_document.remote_id);
    }catch(error){
      set(this, 'isCheckGenerationStarted', false);

      const checkNumberError = error.message === 'Check number has been used';
      if (checkNumberError) {
        this.flashes.addError(this.intl.t('authenticated.checks.new.messages.print.checkNumberError'));
      } else {
        this.flashes.addError(this.intl.t('authenticated.checks.new.messages.print.error'));
      }
    }
  }
}
