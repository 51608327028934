import SearchRoute from 'crakn/routes/search';
import { inject as service } from '@ember/service';

export default class AuthenticatedChecksIndexRoute extends SearchRoute {
    @service router;
    @service session;

    model(params) {
        return this.store.query('tribute-pay/v1/check',{
            ...params 
        });  
    }

    beforeModel() {
        if (!(this.session.currentClient.can_view_financial_configurations && this.session.currentClient.can_view_financial_config_bank_accounts && this.session.currentClient.can_view_check_writing)) {
            this.router.transitionTo('authenticated.dashboard');
        }
    }

    setupController(controller,model) {
        super.setupController(...arguments);
        controller.isChecksEmpty ??= model.length === 0;
    }

    resetController(controller, isExiting) {
        super.resetController(...arguments);

        if (isExiting) {
            controller.setProperties({
                query: '',
                page: 1
            });
        }
    }

}
