import SearchController from 'crakn/controllers/search';
import { tracked } from '@glimmer/tracking';
import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { formatFloat } from 'crakn/utils/data-helpers';

export default class AuthenticatedChecksController extends SearchController{
    @service flashes;
    @service intl;
    @service api;
    @service router;

    @tracked isChecksEmpty;
    @tracked voidingCheck = null;
    @tracked printingCheck = null;

    get zeroChecksMessage() {
      const zeroChecksMatchingSearchMessage = this.intl.t('authenticated.checks.index.search.searchByPayee.messages.noChecksFoundMatchingSeach');
      const zeroChecksAddedMessage = this.intl.t('authenticated.checks.index.table.messages.noChecksAvailable');
  
      return this.isChecksEmpty
      ? (this.search
        ? zeroChecksMatchingSearchMessage
        : zeroChecksAddedMessage)
      : zeroChecksMatchingSearchMessage;
    }

    @action
    confirmVoidingCheck(check) {
        set(check, 'status', 'voided');
        check.save().then(() => {
            set(this, 'voidingCheck', null);
            this.flashes.addSuccess(this.intl.t('authenticated.checks.index.table.messages.voidCheck.success'));
        }).catch(() => {
            this.flashes.addError(this.intl.t('authenticated.checks.index.table.messages.voidCheck.error'));
        })
    }

    @action
    async confirmPrintingCheck(check) {
      set(this, 'printingCheck', null);
      try{
        const response = await this.api.json.post(
          `tfe/documents/${check.bankAccount.checkTemplateId}/generate`,
          {
            body: {
              bank_account_id: check.bankAccountId,
              form_data: {
                  amount: formatFloat(check.amount, false, false, 2, false),
                  bank_account_id: check.bankAccountId,
                  check_id: check.id,
                  date: check.date.format('YYYY-MM-DD'),
                  is_one_time_payee: check.isOneTimePayee,
                  memo: check.memo,
                  number: check.number,
                  payee_id: check.payeeId,
                  payee_name: check.payeeName
                 
              }
            }
          }
        );

        if(!response.ok){
          throw new Error(response);
        }

        this.router.transitionTo('authenticated.checks.check-details.edit', check.id);
      }catch(error){
        this.flashes.addError(this.intl.t('authenticated.checks.new.messages.print.error'));
      }
    }
}
