import Controller from '@ember/controller';
import Search from 'crakn/mixins/search';
import { tracked } from '@glimmer/tracking';
import { action, set } from '@ember/object';
import { debounce } from '@ember/runloop';
import { inject as service } from '@ember/service';

export default class AuthenticatedAdminTfeFormsEditLocationAccessController extends Controller.extend(Search) {
  @service session;
  @service flashes;
  @service api;
  @service store;
  @service intl;

  @tracked canEnableLocations = false;
  @tracked enabledDocuments;
  @tracked enabledLocations;
  @tracked isLoading = true;
  @tracked isPopoutVisible = false;
  @tracked page = 1;
  @tracked query = '';
  @tracked filteredLocations = [];
  @tracked unassignedLocations = [];
  @tracked checkedLocations = [];

  queryParams = ['page'];

  @action
  async openPopout() {
    const locations = await this.getUnassignedLocations();

    set(this, 'unassignedLocations', locations);
    set(this, 'isPopoutVisible', true);
  }

  @action
  async closePopout() {
    set(this, 'isPopoutVisible', false);
    set(this, 'query', '');

    if (this.checkedLocations.length > 0) {
      const checkedLocations = [...this.checkedLocations];

      checkedLocations.forEach((location) => {
        set(location, 'checked', false);
        this.checkedLocations.removeObject(location);
      });
    }

    this.getUnassignedLocations();
  }

  @action
  queryDidChange(event) {
    set(this, 'query', event)
    debounce(this, this.updateFilteredLocations, 150);
  }

  @action
  removeEnabledDocument() {

  }

  @action
  refreshModel() {
    this.send('refreshRoute');
  }

  async getUnassignedLocations() {
    const tfeEnabledDocuments = await this.tfeEnabledDocuments();
    const locations = await this.getLocations();
    const assignedIdentifiers = new Set(tfeEnabledDocuments.map(doc => doc.secondary_remote_identifier));
    const unassignedLocations = locations.filter(location => !assignedIdentifiers.has(location.uuid));

    unassignedLocations.forEach(location => {
        location.checked = false;
    });

    return unassignedLocations;
  }

  async tfeEnabledDocuments() {
    const response = await this.api.json.get('tfe/enabled_documents', {
        params: { document_id: this.model.document.id, per_page: 1000, current_client: true }
    });

    return response.parsedJson.enabled_documents;
  }

  async getLocations() {
    return this.store.query('v2/location', {
      per_page: 1000,
      archived: false,
      client_id: this.session.currentClient.id
    });
  }

  updateFilteredLocations() {
    const query = this.query.toLowerCase();
    const locationFilter = this.unassignedLocations.filter(location => 
      location.name.toLowerCase().includes(query)
    );

    set(this, 'filteredLocations', locationFilter);
  }
}
