import Model, { attr } from '@ember-data/model';
 
export default class TributePayV1CheckModel extends Model {
  @attr('price') amount;
  @attr('object') bankAccount;
  @attr('string') bankAccountId;
  @attr('moment-date') date;
  @attr('string') generatedDocumentId;
  @attr('string') memo;
  @attr('string') number;
  @attr('string') payeeId;
  @attr('string') payeeName;
  @attr('string', { defaultValue: 'contact' }) payeeType;
  @attr('string') status;
}
